import React from "react";
import '../App.css';

//
// React.forwardRef MUST be used for function compoment 
//
// const FooterContent = React.forwardRef((props, ref) => {
//   return (  
//   <div className="footer" ref={ref} style={{marginTop: props.addMarginTop}}>
//     <div className="row">
//       <div className="col-sm-6">
//         <ul>
//           <li>copy right: 2020</li>
//           <li>PetCare HUB Inc.</li>
//           <li>Email: maxkeim@email.com</li>
//           <li>Seoul Korea</li>
//         </ul>
//       </div>
//       <div className="col-sm-6">
//         <ul style={{
//           listStyleType: 'none',
//           fontSize: '1.2rem',
//         }}>
//           <li><i style={{color:'skyblue'}}
//             className="fab fa-facebook-square"></i> Facebook</li>
//           <li><i style={{color:'skyblue'}}
//             className="fab fa-twitter-square"></i> Twitter</li>
//           <li><i style={{color:'orangered'}} 
//             className="fab fa-google-plus-square"></i> Google</li>
//         </ul>
//       </div>
//     </div>
//   </div>
// )})

const FooterContent = React.forwardRef((props, ref) => {
  return (
    <div className="footer" ref={ref} style={{ marginTop: props.addMarginTop }}>
      <div className="container">

        <div className="row">
          <div className="col-10">
            <h5 className="font-light">대표이사 : 천성우    |    상호명 : (주)PSDL    |    사업자등록번호 : 528-87-01357</h5>
            <h5 className="font-light">Copyright © 2021 (주)PSDL. All rights reserved. Font by Malssami & Naver Nanum Font.</h5>

            <button
              onClick={() => window.open("https://www.psdl.co.kr", '_blank')}
              style={{
                backgroundColor: "transparent",
                border: "solid",
                borderColor: "#c0c0c0",
                borderWidth: "1px",
                borderRadius: "3px",
                width: "205px",
                height: "41px",
                marginTop: "17px"
              }}>
              <h5 className="font-bold" style={{ marginBottom: "0px", textAlign: "center" }}>(주)PSDL 바로가기</h5>
            </button>
          </div>
          <div className="col-2" style={{ marginTop: "auto", marginBottom: "auto", textAlign:"right"}}>
            <img src={require("../images/logo2.png")} style={{ width: "51px" }}></img>
          </div>
        </div>
      </div>
    </div >
  )
})

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { marginTop: 0 }
    this.footer = React.createRef()
  }

  render() {
    return (
      <FooterContent ref={this.footer} addMarginTop={this.state.marginTop} />
    )
  }
}
import React from "react";
import Page from "../components/Page"
import "./NotFound.css";


export default function NotFound() {
  return (    
    <Page    
      title="Sorry!, the Page Not Found"
      children={<> </>}
  />
  );
}
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
// import { Auth } from 'aws-amplify'
import { Navbar, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import Routes from './Routes'
import Footer from './pages/Footer'
import logo from './images/logo.png'
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      userHasAuthenticated: false,
    };
    this.setActive=this.setActive.bind(this)
  }

  componentDidMount() {
  }

  setActive(event){
    console.log(event.target.href)
    console.log(event.target.parentNode.childNodes)
    let navLinks = event.target.parentNode.childNodes

    let posId = event.target.href.split('#')[1]

    for(let i = 0; i<navLinks.length; i++){
      console.log("posId : ",posId)
      console.log("navLinks[i] : ", navLinks[i].href.split('#')[1])
      if(navLinks[i].href.split('#')[1] !==posId){
        navLinks[i].classList.remove('active')
      } 
    }  
  }

  render() {
    const { isAuthenticated, userHasAuthenticated } = this.state
    return (
      <div className="App" ref={this.myapp}>
        <Navbar collapseOnSelect expand="lg" variant="light" className="nav-bg-white fixed-top">
          <div className="container">
            <Navbar.Brand>
              <a href="/"><img src = {logo}></img></a>
              
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav>
                  <Nav.Link href="#about" onClick={this.setActive}>About</Nav.Link>
                  <Nav.Link href="#feature" onClick={this.setActive}>Feature</Nav.Link>
                  <Nav.Link href="#service" onClick={this.setActive}>Service</Nav.Link> 
                  <Nav.Link href="#contact" onClick={this.setActive}>Contact</Nav.Link>
            
              </Nav>    
            </Navbar.Collapse>
          </div>

        </Navbar>
        <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
import React, { useState, useEffect } from "react";
import "./Home.css";
import { init, send } from "emailjs-com";
init("user_aKhwIs5QuO9v61Ts6qhSF");



const Wraper = function (props) {
  const { child, id, bgColor, padding } = props
  const styles = {
    container: {
      width: "100%",
      margin: '0px 0 0',
      padding: padding || '0px 0',
      backgroundColor: props.bgColor,
    }
  }

  return (
    <div id={id} className="slide" style={styles.container}>
      {/* <h2 style={{ paddingTop: "100px" }}>12</h2> */}
      {child}
    </div>
  )

}

const Main = () => {
  const [scrollY, setScrollY] = useState(0);
  const [btnStatus, setBtnStatus]= useState(false); //버튼 상태
  const [connectDevice,setConnectDevice] = useState(true);

  const handleFollow = () => {
    setScrollY(window.pageYOffset)
    if(scrollY>300){
      setBtnStatus(true)
    }else{
      setBtnStatus(false)
    }
  }

  const handleTop=() =>{ // 클릭하면 스크롤이 위로 올라가는 함수
    window.scrollTo({
      top:0,
      behavior:"smooth"
    })
    setScrollY(0) //scrollY의 값 초기화
  }
  // useEffect(() => {
  //   console.log("ScrollY is ", scrollY);
  // }, [scrollY])
  useEffect(() => {
    const watch = () => {
      window.addEventListener("scroll", handleFollow)
    }
    watch();
    const isMobile=()=> {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    if(isMobile()){
      // console.log("mobile")
      setConnectDevice(false)
    }
    
    return () => {
      window.removeEventListener('scroll', handleFollow)
    }
  })


  return (
    <div className="jumbotron bg-cover-home LCL">

      <div className="container">
        {connectDevice &&<button className="topBtn" onClick={handleTop}></button>}       
        <div style={{ marginBottom: "5px" }}>
          <h2 className="font-light">제품에 가치를 더하는
            <span className="font-extra-bold text-red">TAPTAG</span>
          </h2>
        </div>
        <div>
          <h4 className="font-light">날이 갈수록 늘어나는 짝퉁 시장.</h4>
          <h4 className="font-light">정품인증 시스템, 이젠 '선택'이 아닌 '<span className="font-bold">필수</span><span className="font-light">'입니다.</span></h4>
        </div>
        <div style={{ width: "205px", height: "45px", marginTop: "35px" }}>
          <button className="bt-store" onClick={() => window.open("https://play.google.com/store/apps/details?id=com.TAPTAG", '_blank')}></button>
        </div>
      </div>
    </div>
  )
}

const About = () => {
  return (
    <div className="jumbotron bg-cover-about CCC">
      <div className="container">
        <div style={{ marginBottom: "39px" }} >
          <h2 className="font-light">What is</h2>
          <h2 className="font-extra-bold text-red"> TAPTAG</h2>
          <h2 className="font-light">?</h2>
        </div>
        <div style={{  marginLeft: "auto", marginRight: "auto",marginBottom:"5vh" }}>
          <h4 className="font-light">TAPTAG는 NFC IC 태그와 보안 알고리즘을 활용해 늘어나는 위/변조 시장으로부터</h4>
          <h4 className="font-light">브랜드를 보호하기 위해 만들어진 정품인증 서비스입니다.</h4>
          <br />
          <h4 className="font-light">더 나아가 어플 내에서 정품 인증하는 과정에서 다양한 마케팅용으로 활용할 수 있습니다.</h4>
        </div>
        <div>
          <img className ="about-content" style={{ width: "50vw" }} src={require("../images/about-content.jpg")} ></img>
        </div>
      </div>
    </div>
  )
}

const Feature = () => {
  return (
    <div>
      <div className="jumbotron bg-cover-feature-1 CCC">
        <div className="container text-white">
          <div style={{ marginBottom: "35px" }} >
            <h2 className="font-light">정품인증에 '</h2>
            <h2 className="font-bold">커스텀</h2>
            <h2 className="font-light">'을 더하다</h2>
          </div>
          <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
            <h4 className="font-light">기존의 정품인증 라벨. 라벨말고 다른 형태는 없을까요?</h4>
            <h4 className="font-light">TAPTAG는 얇고 작은 NFC칩을 기반으로, 라벨 형태 뿐만이 아닌 하드한 형태까지 자유롭게 커스텀 제작이 가능합니다.</h4>
          </div>
        </div>
      </div>

      <div className="jumbotron bg-cover-feature-2 LCL" >
        <div className="container text-white">
          <div style={{ marginBottom: "35px" }} >
            <h2 className="font-bold">소량 제품</h2>
            <h2 className="font-light"> 적용 가능</h2>
          </div>
          <div>
            <h4 className="font-light">정품인증을 하고 싶은 제품이 소량이라 고민이시라면, TAPTAG를 이용해 보세요.</h4>
            <h4 className="font-light">어플 내 관리자 권한을 통해 별도의 하드웨어 기기가 없어도 휴대폰으로 정품 등록이 가능합니다.</h4>
          </div>
        </div>
      </div>

      <div className="jumbotron bg-cover-feature-3 LCL" >
        <div className="container text-white">
          <div style={{ marginBottom: "35px" }} >
            <h2 className="font-bold">표준 보안 알고리즘</h2>
            <h2 className="font-light"> 사용</h2>
          </div>
          <div>
            <h4 className="font-light">기존 정품인증 시스템에 보안이 없어 복제 또는 해킹이 걱정되셨나요?</h4>
            <h4 className="font-light">TAPTAG는 HOTP 기반의 표준 보안 알고리즘을 사용해 더욱 안전하고 해킹 위험없이 이용이 가능합니다.</h4>
          </div>
        </div>
      </div>

      <div className="jumbotron bg-cover-feature-4 CBC">
        <div className="container">
          <div style={{ marginBottom: "35px" }} >
            <h2 className="font-light">2차 거래도</h2>
            <h2 className="font-bold"> 확실하게</h2>
          </div>
          <div>
            <h4 className="font-light">TAPTAG 정품인증 솔루션이 도입된 제품은 영구적으로 정품인증이 가능합니다.</h4>
            <h4 className="font-light">이로 인해 2차 거래에서 발생하는 짝퉁을 진품으로 속여 파는 현상은 줄어들 것이며,</h4>
            <h4 className="font-light">이로 인해 소비자는 어디서든 귀사 브랜드 제품을 안심하고 구매할 수 있을 것입니다.</h4>
          </div>
        </div>
      </div>
    </div>
  )
}
const Service = () => {
  return (
    <div>
      <div className="jumbotron bg-cover-service-1 CTC">
        <div className="container text-white">
          <div style={{ marginBottom: "35px" }} >
            <h2 className="font-bold">마케팅 수단</h2>
            <h2 className="font-light">으로 활용 가능</h2>
          </div>
          <div>
            <h4 className="font-light">정품인증 시 재구매를 위한 구매 사이트와 이벤트, 프로모션 연결, 원하는 제품 광고 노출 등</h4>
            <h4 className="font-light">다양한 마케팅 수단으로 TAPTAG를 활용해보세요!</h4>
          </div>
        </div>
      </div>

      <div className="jumbotron bg-cover-service-2 LCL">
        <div className="container text-white">
          <div style={{ marginBottom: "35px" }} >
            <h2 className="font-light">소비자</h2>
            <h2 className="font-bold"> 빅데이터 제공</h2>
          </div>
          <div>
            <h4 className="font-light"></h4>
            <h4 className="font-light">정품인증 App 데이터를 이용하여 소비자 제품 구매율이 높았던 시기, 장소, 이벤트 등</h4>
            <h4 className="font-light">다양한 데이터를 분석하여 제공합니다.</h4>
          </div>
        </div>
      </div>
    </div>
  )
}
const Contact = () => {
  const [inputs, setInputs] = useState({
    company: "",
    email: "",
    content: ""
  })

  const { company, email, content } = inputs;
  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    });
  }

  const onSubmit = (e) => {
    if (inputs.company == "" || inputs.email == "", inputs.content == "") {
      alert("작성된 내용이 없습니다.")
    }
    else {
      alert("문의 메일을 성공적으로 보냈습니다")
      send("service_7tix4ln", "template_l34m448", inputs)
      // console.log("inputs :", inputs)
      setInputs({
        company: "",
        email: "",
        content: ""
      })
    }
  }

  return (
    <div className="jumbotron bg-cover-contact CCL">
      <div className="container">
        <div className="row">
          <div
            className="col-md-7"
            style={{
              backgroundColor: "white",
              paddingTop: "5vh",
              paddingLeft: "42px",
              paddingRight: "42px"
            }}
          >
            <div>
              <h2 className="font-bold">문의</h2>
              <h2 className="font-light">하기</h2>
            </div>
            <div className="row text-gray" style={{ marginTop: "2vh" }}>
              <div className="col-md-6">
                <h4 className="font-light" style={{ marginBottom: "0.5vh" }}>업체명</h4>
                <input
                  type="text"
                  placeholder="업체명을 입력해주세요."
                  name="company"
                  value={company}
                  onChange={onChange}
                  style={{ width: "100%", marginBottom: "0.5vh" }}
                />
              </div>
              <div className="col-md-6" style={{}}>
                <h4 className="font-light" style={{ marginBottom: "0.5vh" }}>이메일</h4>
                <input
                  type="text"
                  placeholder="이메일을 입력해주세요."
                  name="email"
                  value={email}
                  onChange={onChange}
                  style={{ width: "100%", marginBottom: "0.5vh" }}
                />
              </div>
            </div>
            <div className="text-gray" style={{ marginTop: "0.5vh" }}>
              <h4 className="font-light" style={{ marginBottom: "0.5vh" }}>문의내용</h4>
              <textarea
                type="text"
                placeholder="문의내용을 입력해주세요."
                name="content"
                value={content}
                onChange={onChange}
                style={{ width: "100%", height: "88px", }}
              />
            </div>
            <div style={{ marginTop: "0.5vh", display: 'flex', justifyContent: "flex-end", paddingBottom: "5vh", }}>
              <button className="bt-send" onClick={() => onSubmit()}></button>
            </div>
          </div>
          <div
            className="col-md-5 text-white"
            style={{
              backgroundColor: "#c42127",
              paddingTop: "48px",
              paddingLeft: "40px",
              paddingRight: "40px"
            }}
          >
            <div>
              <h4 className="font-light" style={{ fontStretch: "normal", lineHeight: "normal", letterSpacing: "normal", marginTop: "0px", marginBottom: "0px" }}>TAPTAG</h4>
              <h2 className="font-bold">Information</h2>
            </div>
            <div style={{ border: "solid 1px #fff", marginTop: "11px", backgroundColor: "white" }} />
            <div>
              <h4 className="font-light" style={{ marginTop: "60px", marginBottom: "22px" }}>sales@psdl.co.kr</h4>
              <h4 className="font-light" style={{ marginBottom: "22px" }}>031-984-7027</h4>
              <h4 className="font-light" style={{ marginBottom: "22px", lineHeight: "1.44" }}>경기도 성남시 수정구 대왕판교로 815 판교 제2테크노벨리 기업지원허브 혁신기술존 4층 정보보호 클러스터 466호</h4>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
    this.setActive = this.setActive.bind(this)
  }

  setActive(arr) {
    // console.log(arr)
    let posId = null
    for (let i = 0; i < arr.length; i++) {
      let rect = arr[i].getBoundingClientRect()
      // console.log(arr[i].id, rect.top, rect.right, rect.bottom, rect.left);
      if
        (rect.top > 90 && rect.top < 400) posId = arr[i].id
    }

    if (posId != null) {
      let navLinks = document.getElementsByClassName('nav-link')
      for (let i = 0; i < navLinks.length; i++) {
        if (navLinks[i].href.split('#')[1] === posId) {
          // console.log(navLinks[i].href.split('#')[1], posId)
          navLinks[i].classList.add('active')
        } else {
          navLinks[i].classList.remove('active')
        }
      }
    }
  }

  componentDidMount() {
    // setTimeout(()=>this.setState({loaded: true}), 2000)
    this.setState({ loaded: true })
    let that = this
    setInterval(() => {
      let arr = document.getElementsByClassName('slide')
      that.setActive(arr)
    }, 200)
  }

  render() {
    return (
      <div>
        <Wraper id="main" child={<Main />} />
        <div className="content">
          <Wraper id="about" child={<About />} bgColor='#edf2f0' />
          <Wraper id="feature" child={<Feature />} bgColor='white' />
          <Wraper id="service" child={<Service />} bgColor='#edf2f0' />
          <Wraper id="contact" child={<Contact />} bgColor="white" padding="0 0" />
        </div>
      </div>


    );
  }
}
